import { UtilityService } from '../utility.service';
import { Model } from './model';

export class Make {
    name!: string;
    models!: Model[];

    urlName!: string;

    public constructor(init?: Partial<Make>) {
        this.urlName = UtilityService.toKebabCase(init?.name);
        Object.assign(this, {
            ...init,
            models: init?.models?.map((model) => {
                const m = new Model(model);
                m.make = this.urlName;
                return m;
            }),
        });
    }

    get path() {
        return `/products/make/${this.urlName}/list`;
    }


    getDisplayName() {
        if (this.name === 'Cat') return 'Caterpillar';
        return this.name;
    }
}
