export class MakeContent {
    make!: string;
    model?: string;
    title?: string;
    description?: string;
    text?: string;

    public constructor(init?: Partial<MakeContent>) {
        Object.assign(this, { ...init });
    }
}
