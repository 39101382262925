import { Injectable, inject } from '@angular/core';
import { StoreService } from './store.service';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Make } from './models/make';
import { Part } from './models/part';
import { environment } from '../../environments/environment';
import { PartTypeModelMap } from './models/part-type-model-map';
import { PartType } from './models/part-type';
import { ApplicationInfoService } from './application-info.service';
import { Promotion } from './models/promotion';
import { MakeContent } from './models/make-content';
import { UtilityService } from './utility.service';

@Injectable({
    providedIn: 'root'
})
export class PartsService {
    appInfo = inject(ApplicationInfoService);
    storeService = inject(StoreService);
    http = inject(HttpClient);

    async getPartTypes() {
        const data = await firstValueFrom(this.http.get<PartType[]>(`${this.appInfo.shopUrl()}/api/parts/part-types`));
        const partTypes = data.map((partType) => new PartType(partType));
        this.storeService.setPartTypes(partTypes);
    }

    async getMakes() {
        const data = await firstValueFrom(this.http.get<Make[]>(`${this.appInfo.shopUrl()}/api/parts/makes`));
        const makes = data.map((make) => new Make(make));
        this.storeService.setMakes(makes);
    }

    async getMakeContents() {
        const data = await firstValueFrom(this.http.get<MakeContent[]>(`${this.appInfo.shopUrl()}/api/parts/make-content`));
        const makeContents = data.map((make) => new MakeContent(make));
        this.storeService.setMakeContents(makeContents);
    }

    getMakeContent(make?: string, model?: string) {
        const contents = this.storeService.makeContents();
        if (!make && !model) return contents.filter((content) => '[Products]' === content.make)[0];

        let content: MakeContent | null = null;
        if (model) content = contents.filter((content) => make === content.make && content.model && model.startsWith(content.model))[0];
        content = content ?? contents.filter((content) => make === content.make && !content.model)[0];
        return content;
    }

    async getPartTypeModelMaps() {
        const maps = await firstValueFrom(this.http.get<PartTypeModelMap[]>(`${this.appInfo.shopUrl()}/api/parts/part-type-model-maps`));
        this.storeService.setPartTypeModelMaps(maps);
    }

    async getParts(request: GetPartsRequest) {
        const results = await firstValueFrom(
            this.http.post<Part[]>(`${this.appInfo.shopUrl()}/api/parts/list`, {
                search: request.search,
                partTypeId: request.partTypeId,
                make: request.make,
                model: request.model,
                partNumber: request.partNumber,
                hasPictures: request.hasPictures,
                hasPrice: request.hasPrice,
                isFeatured: request.isFeatured,
                take: request.take,
                excludePartId: request.excludePartId,
                partIds: request.partIds
            })
        );
        return results.map((result) => {
            const part = new Part(result);
            this.setPromotions(part);
            return part;
        });
    }

    async getPart(id: number) {
        const result = await firstValueFrom(this.http.get<Part>(`${this.appInfo.shopUrl()}/api/parts/detail?id=${id}`));
        const part = result ? new Part(result) : null;
        if (part) this.setPromotions(part);
        return part;
    }

    private setPromotions(part: Part) {
        const eligible = this.getEligiblePromotions(part.partTypeId, part.condition, this.storeService.promotions());
        part.setPromotions(eligible);
    }

    async getRelatedParts(id: number) {
        const results = await firstValueFrom(this.http.get<Part[]>(`${this.appInfo.shopUrl()}/api/parts/related-parts?id=${id}`));
        return results.map((result) => {
            const part = new Part(result);
            this.setPromotions(part);
            return part;
        });
    }

    async getPromotions() {
        const promotions = await firstValueFrom(this.http.get<Promotion[]>(`${this.appInfo.shopUrl()}/api/parts/promotions`));
        this.storeService.setPromotions(promotions);
    }

    getEligiblePromotions(partTypeId: number, condition: string, promotions: Promotion[]) {
        return promotions.filter(
            (promotion) =>
                (!promotion.partTypeId || promotion.partTypeId === partTypeId) &&
                (!promotion.condition || promotion.condition === condition)
        );
    }

    static getPromotionPrice(price: number, promotions: Promotion[]) {
        let rate = 1;
        const discountPercent = promotions.reduce((total, promotion) => total + promotion.discount, 0);
        if (discountPercent !== 0) rate = rate - discountPercent / 100;
        return UtilityService.round(price * rate, 2);
    }
}
export class GetPartsRequest {
    search?: string;
    partTypeId?: number;
    make?: string;
    model?: string;
    partNumber?: string;
    hasPictures?: boolean;
    hasPrice?: boolean;
    isFeatured?: boolean;
    take?: number;
    excludePartId?: number;
    partIds?: number[];
}
